<template>
	<div v-if="report" class="p-3 bg-light">
		<div class="d-flex">
			<div class="flex-grow-1">
				<h3>Combinations report #{{ report.Id }} summary</h3>
				<p v-text="report.Notes"></p>
			</div>
			<div v-if="canWrite('spinners')">
				<button class="btn btn-outline-danger" @click="deleteReport">DELETE REPORT</button>
			</div>
		</div>

		<div>
			<div class="font-weight-bold" v-if="report.StartTime">Start time: <span
					v-rel-date="(new Date(report.StartTime))"></span></div>
			<div class="font-weight-bold" v-if="report.EndTime">End time: <span
					v-rel-date="(new Date(report.EndTime))"></span></div>
			<div class="font-weight-bold">Status: {{ report.Status }} ({{ report.Done | numeral('0,0') }} /
				{{ report.Total | numeral('0,0') }})
			</div>
			<div style="max-width: 500px" class="mb-4">
				<div class="progress bg-dark">
					<div class="progress-bar"
							 :class="{'bg-warning progress-bar-animated progress-bar-striped': report.Status === 'running'}"
							 :style="{width: `${((report.Done/report.Total) * 100).toFixed(2)}%`}"></div>
				</div>
			</div>
			<hr>
			<div class="font-weight-bold">Lines: {{ report.ReportOptions.lines }}</div>
			<div class="font-weight-bold">Filters: {{
					report.ReportOptions.reel_filters.map(rf =>
							rf.slice(0).sort().join("/")).map(r => r.length > 0 ? r : 'ANY').join(" - ")
				}}
			</div>
		</div>

		<template v-if="report.Result">

			<tumblings-viewer class="my-3" :tumbling="report.Result.tumbling" v-if="report.Result.tumbling"/>

			<div v-if="report.Result.hits">
				<h4>HITS</h4>
				<table class="table">
					<thead>
					<tr>
						<td>Symbol</td>
						<td>2</td>
						<td>3</td>
						<td>4</td>
						<td>5</td>
					</tr>
					</thead>
					<tr v-for="(multipliers, symbol) in report.Result.hits">
						<td v-text="symbol"></td>
						<td v-for="i in [2,3,4,5]">
							<div v-if="multipliers[i]">
								<div>{{ multipliers[i] }} ({{ (multipliers[i] / totalHits) | numeral('0.00%') }})</div>
								<div class="progress" style="height: 6px">
									<div class="progress-bar"
											 :style="{width: `${(multipliers[i] / totalHits * 100).toFixed(2)}%`}"></div>
								</div>
							</div>
						</td>
					</tr>
				</table>
			</div>

			<hr>

			<div v-for="(winning, currency) in report.Result" v-if="currency !== 'hits'" :key="currency">
				<h5 v-text="currency" class="text-uppercase"></h5>

				<stage-viewer
						v-if="winning.result"
						:reels="winning.result.state !== undefined ? winning.result.state.reels : []" :config="report.Configuration"
						:wins="winning.result.wins"
						:loading="false" :modified-reels="winning.result.modifiedReels"
						:stages="winning.result.stages"
				>
					<div class="my-2">
						<div class="text-small font-weight-bold">RNG:</div>
						<div class="bg-white p-2" v-if="winning.rng">{{ winning.rng.join(", ") }}</div>
					</div>
				</stage-viewer>

				<hr class="my-5">
			</div>
		</template>
	</div>
</template>

<script>
import api from '@/api'
import StageViewer from "@/components/slot-simulator/StageViewer";
import TumblingsViewer from "@/components/combinations/TumblingsViewer";

export default {
	components: {TumblingsViewer, StageViewer},
	data() {
		return {
			report: null,
			interval: null
		}
	},
	async mounted() {
		await this.refresh();
		this.interval = setInterval(this.refresh, 5000)
	},
	destroyed() {
		if (this.interval) {
			clearInterval(this.interval)
		}
	},
	methods: {
		async refresh() {
			this.report = await api.CombinationReports.get(this.$route.params.report_id)
		},
		async deleteReport() {
			await api.CombinationReports.delete(this.$route.params.report_id)
			this.$router.push({
				name: 'projects-editor-uuid-cuuid-tests-combinations-index', params: {
					uuid: this.$route.params.uuid,
					cuuid: this.$route.params.cuuid,
				}
			})
		}
	},
	computed: {
		totalHits() {
			let hits = 0

			if (this.report && this.report.Result.hits) {
				hits = Object.values(this.report.Result.hits).reduce((c, h) => c + Object.values(h).reduce((x, i) => x + i, 0), 0)
			}

			return hits
		}
	}
}
</script>
