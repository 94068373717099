<template>
	<div class="d-flex flex-column">
		<div class="flex-grow-1">
			<table class="table">
				<thead>
				<tr>
					<td></td>
					<td>RNG</td>
					<td>Payout</td>
				</tr>
				</thead>
				<tr v-for="(combination, index) in visibleCombinations">
					<td class="text-right">{{ index + 1 }}</td>
					<td>{{ combination.rng }}</td>
					<td>x{{ combination.payout }}</td>
				</tr>
				<tr v-if="combinations.length > visibleCombinations.length">
					<td colspan="3" class="text-center">
						...
					</td>
				</tr>
			</table>
		</div>
		<div class="text-center py-3">
			<button class="btn text-primary btn-sm" :disabled="!canBeExpanded" @click="open = !open">
				{{ open ? 'SHOW LESS' : `SHOW ${combinations.length - visibleCombinations.length} MORE` }}
			</button>
		</div>
	</div>
</template>
<script>
export default {
	name: 'single-tumbling-viewer',
	props: {
		combinations: {}
	},
	data: () => ({open: false}),
	computed: {
		canBeExpanded(){
			return this.combinations.length > 5
		},
		visibleCombinations(){
			let limit = this.open ? 100 : 5
			return this.combinations.slice(0, limit)
		}
	}
}
</script>
