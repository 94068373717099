<template>
	<div>
		<h4>TUMBLING REPORT</h4>

		<div class="d-flex border justify-content-around py-3 my-3 bg-light">
			<div class="px-4 py-2 text-center">
				<div class="text-small">Total combinations</div>
				<div class="font-weight-bold">{{ totalCombinations.hits | numeral('0,0') }}</div>
			</div>
			<div class="px-4 py-2 text-center">
				<div class="text-small">Total payout</div>
				<div class="font-weight-bold">{{ totalCombinations.payout | numeral('0,0') }}</div>
			</div>
			<div class="px-4 py-2 text-center">
				<div class="text-small">Average payout</div>
				<div class="font-weight-bold">
					{{ (totalCombinations.payout / totalCombinations.hits) | numeral('0,0') }}
				</div>
			</div>
		</div>

		<div class="d-flex w-100 border">
			<div class="bg-white p-3 w-100 mx-1" v-for="(combinations, times) in tumbling">
				<div class="d-flex align-items-center pb-3">
					<div class="bg-primary text-white d-flex align-items-center justify-content-center border border-secondary"
							 style="height: 32px; width: 32px">{{ times }}
					</div>
					<div class="ml-2">
						<h5 class="py-0 my-0">
							<template v-if="times > 1">
								Tumbling {{ times < 5 ? 'ONLY' : '' }} {{ times }}{{ times >= 5 ? '+' : '' }} times
							</template>
							<template v-else>
								Without tumbling
							</template>
						</h5>
					</div>
				</div>
				<div class="my-2 d-flex justify-content-around flex-wrap">
					<div class="bg-dark text-white p-2 text-center flex-grow-1 m-1">
						<div class="text-small">Total combinations</div>
						<div class="font-weight-bold">{{ combinations.counters.hits | numeral('0,0') }}</div>
						<div class="text-small">- {{ (combinations.counters.hits / totalCombinations.hits) | numeral('0.0%')  }} -</div>
					</div>
					<div class="bg-dark text-white p-2 text-center flex-grow-1 m-1">
						<div class="text-small">Total payout</div>
						<div class="font-weight-bold">{{ combinations.counters.payout | numeral('0,0') }}</div>
						<div class="text-small">- {{ (combinations.counters.payout / totalCombinations.payout) | numeral('0.0%')  }} -</div>
					</div>
					<div class="bg-dark text-white p-2 text-center flex-grow-1 m-1">
						<div class="text-small">Average payout</div>
						<div class="font-weight-bold">
							{{ (combinations.counters.payout / combinations.counters.hits) | numeral('0,0') }}
						</div>
						<div class="text-small">- {{ (combinations.counters.payout / combinations.counters.hits) / (totalCombinations.payout / totalCombinations.hits) | numeral('0.0%')  }} -</div>
					</div>
				</div>
				<single-tumbling-viewer :combinations="combinations.combos" :times="times"/>
			</div>
		</div>
	</div>
</template>
<script>
import SingleTumblingViewer from "./SingleTumblingViewer";

export default {
	name: 'tumblings-viewer',
	components: {SingleTumblingViewer},
	props: {
		tumbling: {}
	},
	computed: {
		totalCombinations() {
			return Object.values(this.tumbling).map(t => t.counters).reduce((c, count) => {
				c.payout += count.payout
				c.hits += count.hits
				return c
			}, {
				hits: 0,
				payout: 0
			})
		}
	}
}
</script>
